<template>
  <div
    v-if="userName"
    class="containers"
  >
    <div class="hint">
      <!-- <p>王小明您好！</p>
    <p>歡迎來到櫻花淨水器～</p> -->
      <p :style="{color: blue}">
        {{ userName.CustName }}您好,歡迎回來 !
      </p>
    </div>
    <div class="personal-data">
      <div class="pic">
        <img
          v-if="userName.CustPictureUrl"
          :src="userName.CustPictureUrl"
          alt=""
          @click.stop="imgClick"
        >
        <img
          v-else
          src="./../assets/img/user_name.png"
          alt=""
          @click="imgClick"
        >
      </div>
      <div class="txt-name">
        {{ userName.CustName }}
      </div>
      <!-- <h3 class="txt-title">消費者會員代號：A05541</h3> -->
      <div class="btn">
        <i class="mcicon-3" />
        {{ userName.Mobile }}
      </div>
    </div>
    <div class="list">
      <el-card>
        <div class="list-item">
          <img
            src="./../assets/img/icon_wuzi.png"
            alt=""
          >
          <div class="item-site">
            <span>驗證地址</span>
            <p>{{ userName.CustAddress }}</p>
          </div>
        </div>
      </el-card>
      <el-card>
        <div class="list-item">
          <img
            v-if="!userName.LineBinding"
            src="./../assets/img/icon_line.png"
            alt=""
          >
          <img
            v-else
            src="./../assets/img/icon_line2.png"
            alt=""
          >
          <div class="item-site">
            <span>LINE綁定狀態</span>
            <p v-if="!userName.LineBinding">
              未綁定
            </p>
            <p v-else>
              已绑定
            </p>
          </div>
          <span
            v-if="!userName.LineBinding"
            class="site-bind"
            @click="isDialogShows=true"
          >去綁定</span>
        </div>
        <binding-dialog
          :is-dialog-show="isDialogShows"
          :go-no-click="goNoClick"
          :go-to-click="goToClick"
        />
      </el-card>
    </div>
  </div>
</template>

<script>
import BindingDialog from './BindingDialog'
import storage from './../storage'
import { getCustomerByLineMid, getCustByEncodeProdcutId } from '../api/api'
export default {
  name: 'PersonalCenter',
  components: {
    BindingDialog
  },
  props: {
    imgClick: { type: Function, default: () => {} },
    blue: { type: String, default: '' }
  },
  data () {
    return {
      isDialogShows: false,
      isShow: false,
      copyData: null,
      EncodeProductId: '',
      LINEMid: '',
      userName: {},
      fullWidth: document.documentElement.clientWidth
    }
  },
  async mounted () {
    // await this.liffInit(process.env.VUE_APP_LIFFID_SEARCH_PRODUCTS)
    // this.LINEMid = storage.getItem('LINEMid')
    // console.log('personal', this.LINEMid)

    setTimeout(() => {
      this.EncodeProductId = storage.getItem('EncodeProductId')
      this.LINEMid = storage.getItem('LINEMid')
      console.log('')
      if (!this.EncodeProductId) {
        this._getCustomerByLineMid()
      } else {
        // console.log(this.EncodeProductId)
        this._getCustByEncodeProdcutId()
      }
    }, 250)
  },
  methods: {
    _getCustByEncodeProdcutId () {
      const EncodeProductId = storage.getItem('EncodeProductId')
      getCustByEncodeProdcutId({
        EncodeProductId
      }).then(res => {
        // console.log('p', res)
        if (res.status === 200) {
          this.userName = res.data.Data
          storage.setItem('userName', res.data.Data)
        }
      })
    },
    _getCustomerByLineMid () {
      setTimeout(() => {
      // const LINEMid = this.$cookie.get('LINEMid')
        const LINEMid = storage.getItem('LINEMid')
        console.log('timeout', LINEMid)
        getCustomerByLineMid({
          LINEMid
        }).then(res => {
          // console.log('m', res)
          if (res.status === 200) {
            this.userName = res.data.Data
            storage.setItem('userName', res.data.Data)
          }
        })
      }, 3000)
    },
    goToClick () {
      window.location.href = 'https://line.me/R/ti/p/@sakura.tw'
      this.isDialogShows = false
    },
    goNoClick () {
      this.isDialogShows = false
    },
    onCopy () {
      this.$message({
        message: '複製成功！',
        type: 'success'
      })
    },
    onError () {
      this.$message({
        message: '複製失敗！',
        type: 'error'
      })
    }

  }
}
</script>

<style scoped lang="scss">
.containers {
  .hint {
    margin-bottom: 6rem;
    p {
      margin-left: 1.6rem;
      font-size: 2.4rem;
      line-height: 3.3rem;
      font-weight: 700;
      color: #303133;
      font-family: PingFang SC, PingFangSC-Medium, PingFangSC-Bold, PingFangSC-Semibold;
    }
  }
  .personal-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    .pic {
      width: 10.2rem;
      height: 10.2rem;

      border-radius: 50%;
      border: .2rem solid #fff;
      overflow: hidden;
      margin-bottom: 2rem;
      img {
        width: 100%;
        height: 100%;
        background-color: #E5E5E5;
      }
    }
    .txt-name {
      margin-bottom: .4rem;
      font-size: 1.4rem;
      color: #868686;
      padding-bottom: 3rem;
    }
    .txt-title {
      line-height: 2.5rem;
      font-style: 1.8rem;
      color: #3D3D3D;

    }
    .btn {
      height: 3.8rem;
      background-color: #C8161D;
      box-shadow: 0 1.2rem 3rem 0 rgba(1,181,240,.15);
      border-radius: .6rem;
      color: #fff;
      margin: 0 auto;
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;
      cursor: pointer;
      .mcicon-3 {
        margin-right: 1rem;
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    .el-card {
      width: 80%;
      margin-bottom: 1.5rem;
      border-radius: 0rem;
      .list-item {
        height: 3rem;
        display: flex;
        align-items: center;
        img {
          flex: 0 0 2rem;
          width: 2rem;
          height: 2rem;
          margin-right: .8rem;
        }
        .item-site {
          flex: 1;
          display: flex;
          flex-direction: column;
            font-size: 1.2rem;
          span {
            color: #BDBDBD;
            margin-bottom: .5rem;
          }
          p {
            color: #3D3D3D;
          }
        }
        .site-bind {
            display: block;
            width: 8rem;
            height: 2.8rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff1f1;
            border-radius: .4rem;
            font-size: 1.2rem;
            font-weight: 500;
            color: #C8161D;
            line-height: 1.7rem;
            border-color: transparent;
          }
      }

    }
  }
}
@media only screen and (max-width:993px) {
  .list .el-card {
    width: 100%!important;
  }
}
</style>
